import {
  Box,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthenticatedUserRO } from "@intentsify/dto";
import { useMutation } from "@tanstack/react-query";
import { AgreementScreenDefinition, ResetPasswordScreenDefinition } from "auth";
import { Button } from "components";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router";
import { YourCampaignsScreenDefinition } from "screens";
import { useUser } from "store/store.hooks";
import { useAppStore } from "store/useAppStore";
import { isCustomApiError } from "types";
import { handleApiError } from "utils";
import { z } from "zod";
import { signInRequest } from "./LoginForm.requests";
import { ResetPasswordLink } from "./ResetPasswordLink";
import { DateTime } from "luxon";
import { pluralize } from "@intentsify/utils";
import {
  MAX_UNSUCCESSFUL_LOGIN_ATTEMPTS,
  LOCKOUT_TIME_IN_MINUTES,
} from "@intentsify/types";

const validationSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
});

type FieldValues = z.infer<typeof validationSchema>;

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onChange",
    resolver: zodResolver(validationSchema),
  });

  const user = useUser();
  const setUser = useAppStore.use.setUser();

  const navigate = useNavigate();
  const toast = useToast();

  const { mutate, isSuccess, isLoading } = useMutation(signInRequest, {
    onSuccess: ({ user }: AuthenticatedUserRO) => {
      setUser(user);

      if (user.isActive) {
        toast.closeAll();
        toast({
          title: "Welcome back!",
          status: "success",
        });
      }
    },
    /**
     * We want to handle invalid credentials in a special way.
     * If it's not 401InvalidCredentials or 403AccountLockedOut let it be handled by handleApiError.
     */
    onError: (err) => {
      if (isCustomApiError(err)) {
        if (err.response.internalCode === "401InvalidCredentials") {
          toast({
            title: (
              <>
                Invalid email address or password. Did you forget your password?
                Click
                <Link
                  onClick={() => {
                    toast.closeAll();
                    navigate(ResetPasswordScreenDefinition.navigate());
                  }}
                  textDecoration="underline"
                >
                  {" "}
                  here{" "}
                </Link>
                to reset.
                {err.response.metadata?.unsuccessfulLoginAttempts && (
                  <Text>
                    Your account will be locked out for{" "}
                    {LOCKOUT_TIME_IN_MINUTES} minutes after{" "}
                    {MAX_UNSUCCESSFUL_LOGIN_ATTEMPTS -
                      Number(
                        err.response.metadata.unsuccessfulLoginAttempts
                      )}{" "}
                    more unsuccessful login{" "}
                    {pluralize(
                      MAX_UNSUCCESSFUL_LOGIN_ATTEMPTS -
                        Number(err.response.metadata.unsuccessfulLoginAttempts),
                      "attempt"
                    )}
                    .
                  </Text>
                )}
              </>
            ),
            status: "warning",
            isClosable: true,
            duration: 25 * 1000,
          });

          return;
        }

        if (err.response.internalCode === "403AccountLockedOut") {
          const unlockTime = err.response.metadata?.lockedOutTime
            ? DateTime.fromISO(
                String(err.response.metadata.lockedOutTime)
              ).plus({ minutes: LOCKOUT_TIME_IN_MINUTES })
            : null;
          const diff = unlockTime
            ? unlockTime.diffNow(["minutes"]).toObject()
            : null;

          toast({
            title: (
              <>
                <Text>{err.response.message}</Text>

                {diff && (
                  <Text>
                    Your account will be unlocked in{" "}
                    {Math.ceil(Number(diff.minutes))}{" "}
                    {pluralize(Math.ceil(Number(diff.minutes)), "minute")}.
                  </Text>
                )}
              </>
            ),
            status: "warning",
            isClosable: true,
            duration: 25 * 1000,
          });

          return;
        }
      }

      handleApiError(err, navigate, toast);
    },
    retry: false,
  });

  const onSubmit = (values: FieldValues) => {
    mutate(values);
  };

  useEffect(() => {
    if (isSuccess && !user?.isActive) {
      toast({
        title:
          "Before continuing you have to agree to the terms and conditions.",
        status: "info",
      });
    }
  }, [isSuccess, user?.isActive, toast]);

  if (isSuccess) {
    return (
      <Navigate
        to={
          user?.isActive
            ? YourCampaignsScreenDefinition.navigate()
            : AgreementScreenDefinition.navigate()
        }
      />
    );
  }

  return (
    <Flex justifyContent="center">
      <chakra.form
        onSubmit={(data) => {
          handleSubmit(onSubmit)(data);
        }}
        w="100%"
      >
        <SimpleGrid columns={1} spacing={4}>
          <FormControl isInvalid={!!errors?.email?.message} isRequired>
            <FormLabel color="white">Email</FormLabel>
            <Input
              {...register("email")}
              bg="gray.100"
              color="gray.600"
              _placeholder={{ color: "gray.400" }}
              type="email"
              name="email"
              placeholder="Email"
            />
            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors?.password?.message} isRequired>
            <FormLabel color="white">Password</FormLabel>
            <Input
              {...register("password")}
              bg="gray.100"
              color="gray.600"
              _placeholder={{ color: "gray.400" }}
              type="password"
              placeholder="Password"
              name="password"
            />
            <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
          </FormControl>

          <Box>
            <ResetPasswordLink />
          </Box>

          <Button
            size="md"
            type="submit"
            fullWidth
            isLoading={isLoading}
            isDisabled={!!errors.email || !!errors.password || isLoading}
          >
            Login
          </Button>
        </SimpleGrid>
      </chakra.form>
    </Flex>
  );
};

export { LoginForm };
