import { Flex, GridItem, useColorModeValue } from "@chakra-ui/react";
import { colors } from "theme";
import { WithChildren } from "types";

type CellProps = WithChildren & {
  bg?: string;
  contentAlignment?: "left" | "right" | "center";
  noBorder?: boolean;
  width?: number;
};

const Cell = ({
  children,
  bg = "transparent",
  contentAlignment = "center",
  noBorder = false,
  width,
}: CellProps) => {
  const borderColor = useColorModeValue(colors.gray["100"], colors.gray["800"]);

  return (
    <GridItem
      h="100%"
      borderLeft={noBorder ? undefined : `1px solid ${borderColor}`}
    >
      <Flex
        h="100%"
        w={width}
        alignItems="center"
        justifyContent={contentAlignment}
        px={2}
        py={2}
        bg={bg}
        wordBreak="break-all"
      >
        {children}
      </Flex>
    </GridItem>
  );
};

export { Cell };
