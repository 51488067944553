import { BarChart, Card, MIN_MODAL_CONTENT_HEIGHT } from "components";
import { useMemo, useState } from "react";
import { ChartExport, useExportableChart } from "shared/components";
import { useBuyingGroupInsight } from "../useBuyingGroupInsight";

interface BuyingGroupInterestBreakdownProps {
  campaignId: number;
}

export function BuyingGroupInterestBreakdown(
  props: BuyingGroupInterestBreakdownProps
) {
  const [isExpanded, setIsExpanded] = useState(false);
  const buyingGroupInsight = useBuyingGroupInsight(props.campaignId);
  const data = useMemo(() => {
    const data = (buyingGroupInsight.data?.interest_level_distribution ?? [])
      .filter((item) => item.accounts_per_interest_level && item.interest_level)
      .map((item) => ({
        // TODO: as unknown will be fixed by ts 5.5
        value: item.accounts_per_interest_level as unknown as number,
        label: item.interest_level as unknown as string,
      }));

    const orderedData = data.sort((a, b) => {
      const order: { [key: string]: number } = { Low: 0, Medium: 1, High: 2 };
      return order[a.label] - order[b.label];
    });

    return orderedData;
  }, [buyingGroupInsight.data?.interest_level_distribution]);

  const chart = useExportableChart({
    title: "Buying Group Interest Breakdown",
    campaignId: props.campaignId,
    chart: (
      <BarChart
        stretch
        data={data}
        orientation="vertical"
        valueAxisLabel="Accounts"
        labelAxisLabel="Interest Level"
        maxXAxisHeight={70}
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
      />
    ),
  });

  return (
    <Card
      isLoading={buyingGroupInsight.isFetching}
      onExpandedChange={setIsExpanded}
      title={chart.title}
      hasData={!buyingGroupInsight.isFetching && data?.length > 0}
      isExpandable
      actions={
        <ChartExport
          size="small"
          campaignId={1}
          title={chart.title}
          onExportPNG={chart.downloadAsPng}
          data={data ?? []}
        />
      }
    >
      {chart.component}
      {chart.exportComponent}
    </Card>
  );
}
