import { useDisclosure, useToast } from "@chakra-ui/react";
import { SignalSelectionSource } from "@intentsify/types";
import { isValidKeyword, sanitizeKeyword } from "@intentsify/utils";
import { useMutation } from "@tanstack/react-query";
import { ShouldOverwritePrompt } from "components";
import uniqBy from "lodash/uniqBy";
import * as Papa from "papaparse";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { isCustomApiError } from "types";
import { KeywordItem, SelectionMode, VerifiedKeywords } from "types/Keyword";
import {
  getToastSettingsFromHttpStatusCode,
  handleApiError,
  parseCSV,
} from "utils";
import {
  saveCampaignKeywords,
  verifyCampaignKeywords,
} from "./UploadKeywords.requests";

import { UploadKeywordsModal } from "./UploadKeywordsModal";
import { UploadKeywordsValidationError } from "./UploadKeywordsValidationError";

type UploadKeywordsProps = {
  /**
   * If campaignId is provided, keywords will be verified, saved and `setSelectedKeywords` will be called,
   * if campaignId is not provided keywords will be verified and `setSelectedKeywords` will be called
   */
  campaignId?: number;
  keywordsFile: File;
  clearKeywordsFile: () => void;
  selectedKeywords: KeywordItem[];
  setSelectedKeywords: (
    keywords: KeywordItem[],
    selectionMode: SelectionMode
  ) => void;
};

export const UploadKeywords = ({
  campaignId,
  keywordsFile,
  clearKeywordsFile,
  selectedKeywords,
  setSelectedKeywords,
}: UploadKeywordsProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const keywordsFileNameRef = useRef<string>("");
  const [verifiedKeywords, setVerifiedKeywords] = useState<{
    data: VerifiedKeywords[];
    toMergeWith: KeywordItem[];
  }>({ data: [], toMergeWith: [] });

  const {
    isOpen: isKeywordsModalOpen,
    onOpen: onKeywordsModalOpen,
    onClose: onKeywordsModalClose,
  } = useDisclosure();

  /**
   * `shouldProcessKeywordsAnyway` is set when user uploads invalid keywords and clicks "proceed anyway".
   * The button for this action is within the toast component which does not have access
   * to the current scope of the KeywordsAndTopicsForm.
   * I cannot call `onRemoveInvalidKeywords` from within `onClick` callback as it will
   * not have access to the most recent `keywordFiles`. A workaround is to use useEffect when
   * `shouldProcessKeywordsAnyway` changes.
   */
  const [shouldProcessKeywordsAnyway, setShouldProcessKeywordsAnyway] =
    useState<
      | {
          should: boolean;
          toMergeWith: KeywordItem[] | undefined;
        }
      | undefined
    >();

  const {
    isOpen: isShouldOverwriteKeywordsModalOpen,
    onOpen: onShouldOverwriteKeywordsModalOpen,
    onClose: onShouldOverwriteKeywordsModalClose,
  } = useDisclosure();

  const { mutate: saveKeywords, isLoading: isLoadingSaveKeywords } =
    useMutation(saveCampaignKeywords(campaignId ?? 0), {
      onMutate: () => {
        setVerifiedKeywords({
          data: [],
          toMergeWith: [],
        });

        clearKeywordsFile();
      },
      onSuccess: (savedKeywords, { toMergeWith }) => {
        const unique = uniqBy(
          [
            ...savedKeywords.map((k) => ({
              label: k.keyword.toLowerCase(),
              value: k.keywordId,
              metaData: {
                source: SignalSelectionSource.Csv,
              },
            })),
            ...(toMergeWith ?? []),
          ],
          (k) => k.value
        );

        setSelectedKeywords(unique, SelectionMode.SELECT);

        onKeywordsModalClose();
      },
    });

  const { mutate: verifyKeywords, isLoading: isLoadingVerifyKeywords } =
    useMutation(verifyCampaignKeywords, {
      onMutate: () => {
        setVerifiedKeywords({ data: [], toMergeWith: [] });
      },
      onSuccess: (uploadedKeywords, { toMergeWith }) => {
        if (uploadedKeywords.some((k) => !k.exists)) {
          setVerifiedKeywords({
            data: uploadedKeywords,
            toMergeWith: toMergeWith ?? [],
          });

          return;
        }

        if (campaignId) {
          saveKeywords({
            fileName: keywordsFileNameRef.current,
            keywords: uploadedKeywords.map((k) => k.keyword),
            toMergeWith,
          });
        } else {
          setSelectedKeywords(
            uploadedKeywords.map((k) => ({
              value: k.keyword,
              label: k.keyword,
            })),
            SelectionMode.SELECT
          );
          onKeywordsModalClose();
        }
      },
      onError: (error, { toMergeWith }) => {
        const shouldDisplayToast =
          isCustomApiError(error) &&
          error.response.internalCode === "400InvalidKeywords";

        if (shouldDisplayToast) {
          toast({
            title: (
              <UploadKeywordsValidationError
                error={error}
                onProceedAnyway={() => {
                  setShouldProcessKeywordsAnyway({
                    toMergeWith,
                    should: true,
                  });
                }}
              />
            ),
            ...getToastSettingsFromHttpStatusCode(error.response.statusCode),
            duration: null,
          });
        }

        if (!shouldDisplayToast) {
          handleApiError(error, navigate, toast);
        }

        onKeywordsModalClose();
      },
      retry: 0,
    });

  const processKeywordsFile = useCallback(
    (file: File, toMergeWith?: KeywordItem[]) => {
      onKeywordsModalOpen();
      keywordsFileNameRef.current = file.name;
      verifyKeywords({ toMergeWith, file });
    },
    [onKeywordsModalOpen, verifyKeywords]
  );

  const onRemoveInvalidKeywords = useCallback(
    (file: File, toMergeWith: KeywordItem[] | undefined) => {
      toast.closeAll();

      const reader = new FileReader();

      reader.onload = async (event) => {
        if (event?.target?.result) {
          const { data: keywords } = await parseCSV<"keyword">(
            event.target.result.toString(),
            { keyword: { validator: (v) => v, isOptional: false } }
          );

          const validKeywords = [
            ...keywords,
            ...(toMergeWith?.map((i) => ({ keyword: String(i.label) })) ?? []),
          ].filter(
            (k) =>
              isValidKeyword(sanitizeKeyword({ keyword: k.keyword })).result
          );

          const blob = new Blob([Papa.unparse(validKeywords)]);

          processKeywordsFile(new File([blob], "keywords.csv"));
        }
      };

      reader.readAsText(file);
    },
    [processKeywordsFile, toast]
  );

  useEffect(() => {
    if (shouldProcessKeywordsAnyway?.should && keywordsFile) {
      setShouldProcessKeywordsAnyway(undefined);

      onRemoveInvalidKeywords(
        keywordsFile,
        shouldProcessKeywordsAnyway.toMergeWith
      );
    }
  }, [keywordsFile, onRemoveInvalidKeywords, shouldProcessKeywordsAnyway]);

  useEffect(() => {
    if (keywordsFile) {
      if (selectedKeywords.length > 0) {
        onShouldOverwriteKeywordsModalOpen();
        return;
      }
      processKeywordsFile(keywordsFile);
    }
  }, [
    keywordsFile,
    onShouldOverwriteKeywordsModalOpen,
    processKeywordsFile,
    selectedKeywords.length,
  ]);

  return (
    <>
      <ShouldOverwritePrompt
        subjectPlural="keywords"
        isOpen={isShouldOverwriteKeywordsModalOpen}
        onReplace={() => {
          if (keywordsFile) {
            // Replace previous csv selection
            setSelectedKeywords(
              selectedKeywords.filter(
                (k) => k.metaData?.source === SignalSelectionSource.Csv
              ),
              SelectionMode.UNSELECT
            );
            onShouldOverwriteKeywordsModalClose();
            processKeywordsFile(keywordsFile);
          }
        }}
        onMerge={() => {
          if (keywordsFile) {
            onShouldOverwriteKeywordsModalClose();
            processKeywordsFile(keywordsFile, selectedKeywords);
          }
        }}
        onClose={() => {
          onShouldOverwriteKeywordsModalClose();
          clearKeywordsFile();
        }}
      />

      <UploadKeywordsModal
        isOpen={isKeywordsModalOpen}
        onClose={() => {
          onKeywordsModalClose();
          clearKeywordsFile();
        }}
        verifiedKeywords={verifiedKeywords}
        isLoading={isLoadingVerifyKeywords || isLoadingSaveKeywords}
        saveKeywords={(keywords) => {
          if (campaignId) {
            saveKeywords({
              keywords,
              fileName: keywordsFileNameRef.current,
              toMergeWith: verifiedKeywords.toMergeWith,
            });
          } else {
            setSelectedKeywords(
              keywords.map((k) => ({
                value: k,
                label: k,
              })),
              SelectionMode.SELECT
            );
            onKeywordsModalClose();
          }
        }}
      />
    </>
  );
};
