import { useQuery } from "@tanstack/react-query";
import { getCustomersByEmployeeSize } from "../../Visualization.requests";
import { useMemo, useState } from "react";
import {
  ChartExport,
  useExportableChart,
} from "../../../../../../../../shared/components";
import { Card, MIN_MODAL_CONTENT_HEIGHT, PieChart } from "components";
import { Props } from "../../../../WebAnalytics.types";

export const CustomersByEmployeeSize = ({
  pixelId,
  accountView,
  campaignId,
  startDate,
  endDate,
  accountsIds,
  visitedPages,
  includeIspTraffic,
  includeKnownBotTraffic,
  includePotentialBotTraffic,
}: Props) => {
  const { data: rawData, isFetching: isFetching } = useQuery({
    queryKey: [
      "webAnalyticsCustomersByEmployeeSize",
      pixelId,
      accountView,
      campaignId,
      startDate,
      endDate,
      accountsIds,
      visitedPages,
      includeIspTraffic,
      includeKnownBotTraffic,
      includePotentialBotTraffic,
    ],
    queryFn: () =>
      getCustomersByEmployeeSize(pixelId, {
        accountView,
        campaignId,
        startDate,
        endDate,
        accountsIds,
        visitedPages,
        includeIspTraffic,
        includeKnownBotTraffic,
        includePotentialBotTraffic,
      }),
  });

  const [isExpanded, setIsExpanded] = useState(false);

  const data = useMemo(() => {
    if (isFetching || !rawData?.data) {
      return [];
    }

    return rawData.data.map(({ value, label }) => ({
      label,
      value: Number(value),
    }));
  }, [rawData, isFetching]);

  const chart = useExportableChart({
    title: "Accounts Visited by Employee Size",
    campaignId: campaignId || undefined,
    chart: (
      <PieChart
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        stretch
        legendPosition="bottom"
        data={data ?? []}
        displayAsPercent={true}
      />
    ),
    dates: {
      start: startDate,
      end: endDate,
    },
  });

  return (
    <Card
      onExpandedChange={setIsExpanded}
      isLoading={isFetching}
      title={chart.title}
      hasData={!isFetching && Boolean(data?.length)}
      isExpandable
      actions={
        <ChartExport
          size="small"
          campaignId={campaignId || undefined}
          title={chart.title}
          onExportPNG={chart.downloadAsPng}
          data={data}
        />
      }
    >
      {chart.component}
      {chart.exportComponent}
    </Card>
  );
};
