import { AxiosError, AxiosInstance } from "axios";
import { queryClient } from "queryClient/PortalQueryClientProvider";
import { HttpStatus } from "types";
import { CustomApiError } from "utils";
import { axiosMock } from "../test/http-mock/axiosMock";
import { refreshAccessToken, signOutRequest } from "./api.requests";
import { isAuthRefreshing, setIsAuthRefreshing } from "./isAuthRefreshing";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const makeApiAxiosServiceProxy = (apiService: AxiosInstance) => {
  const isJestTest = process.env.JEST_WORKER_ID !== undefined;

  if (isJestTest) {
    return axiosMock;
  }

  apiService.interceptors.response.use(undefined, async (error: unknown) => {
    if (
      !isAuthRefreshing(apiService) &&
      error instanceof AxiosError &&
      error.response?.status === HttpStatus.UNAUTHORIZED &&
      error.response?.data?.internalCode !== "401PersonalInfoExpiredToken"
    ) {
      try {
        setIsAuthRefreshing(apiService, true);
        await refreshAccessToken();
        setIsAuthRefreshing(apiService, false);
        queryClient.cancelQueries();
        queryClient.invalidateQueries();
      } catch {
        setIsAuthRefreshing(apiService, false);
        void signOutRequest();

        throw new CustomApiError(
          error.message,
          {
            statusCode: error.response?.status ?? 0,
            internalCode: error.response?.data?.internalCode,
            message: error.response?.data?.message,
            metadata: error.response?.data?.metadata,
          },
          error.response?.config,
          error.request?.responseURL as string
        );
      }
    }

    // Handle HTTP errors with response
    if (error instanceof AxiosError && error.response) {
      throw new CustomApiError(
        error.message,
        {
          statusCode: error.response?.status ?? 0,
          internalCode: error.response?.data?.internalCode,
          message: error.response?.data?.message,
          metadata: error.response?.data?.metadata,
        },
        error.response?.config,
        error.request?.responseURL as string
      );
    }

    // Handle network errors with retries
    if (
      error instanceof AxiosError &&
      (!error.response || error.code === "ERR_NETWORK")
    ) {
      const maxRetries = 3;
      let retryCount = 0;
      const originalConfig = error.config ?? {}; // Fallback to empty object if config is undefined

      while (retryCount < maxRetries) {
        retryCount++;
        const delayMs = Math.pow(2, retryCount - 1) * 1000; // Exponential backoff: 1s, 2s, 4s

        try {
          await delay(delayMs);
          console.log(
            `Retrying request (${retryCount}/${maxRetries}) after ${delayMs}ms delay`
          );
          return await apiService(originalConfig); // Retry the original request
        } catch (retryError) {
          if (retryCount === maxRetries) {
            throw new CustomApiError(
              "Network error after retries",
              {
                statusCode: 500,
                internalCode: "NETWORK_RETRY_EXHAUSTED",
                message: (retryError as Error).message,
              },
              originalConfig,
              error.request?.responseURL as string
            );
          }
        }
      }
    }

    // Fallback for unexpected errors (e.g., TypeError)
    if (error instanceof Error) {
      throw error;
    }

    // If error is not an instance of Error (very rare), throw a generic one
    throw new Error("Unknown error occurred");
  });

  return apiService;
};

export const makeApiFetchServiceProxy = (
  fetch: WindowOrWorkerGlobalScope["fetch"]
) => {
  setIsAuthRefreshing(fetch, false);

  return new Proxy(fetch, {
    async apply(
      target,
      thisArg,
      args: [input: RequestInfo | URL, init?: RequestInit | undefined]
    ) {
      const response = await target.apply(thisArg, args);

      if (
        !isAuthRefreshing(fetch) &&
        response.status === HttpStatus.UNAUTHORIZED
      ) {
        try {
          setIsAuthRefreshing(fetch, true);
          await refreshAccessToken();
          setIsAuthRefreshing(fetch, false);
          queryClient.cancelQueries();
          queryClient.invalidateQueries();
        } catch {
          setIsAuthRefreshing(fetch, false);
          void signOutRequest();
          throw new CustomApiError(response.statusText, {
            statusCode: HttpStatus.UNAUTHORIZED,
          });
        }
      }

      return response;
    },
  });
};
