import { Box, Flex } from "@chakra-ui/layout";
import { Heading, Text } from "@chakra-ui/react";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { Component } from "react";
import { AiOutlineArrowLeft, AiOutlineReload } from "react-icons/ai";
import { useAppStore } from "store/useAppStore";
import { Version, isCustomApiError } from "types";
import { CustomApiError } from "utils";
import { Badge } from "../Badge";
import { Button } from "../Button";
import { SomethingWrong } from "../SomethingWrong";

type ErrorBoundaryProps = {
  children: React.ReactNode;
  sx?: React.CSSProperties;
  queryClient?: QueryClient;
  version?: Version;
};

const withQueryClientAndBuild =
  (Component: React.ComponentType<ErrorBoundaryProps>) => (props: any) => {
    const queryClient = useQueryClient();
    const build = useAppStore.use.version();

    return <Component queryClient={queryClient} build={build} {...props} />;
  };

/**
 * The ErrorBoundary is used to catch exceptions, it will replace the view with an error message.
 * Additionally it provides a way to attempt recovery:
 * - clicking "Click here to retry" will try re-mount the component,
 * - if "Click here to retry" has been clicked 3 times it will reload the whole tab
 * - "Go Back" button returns to the previous page
 */
class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  { error: Error | CustomApiError | undefined; retryAttempts: number }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined, retryAttempts: 0 };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  static getErrorMessage(error: Error | CustomApiError) {
    if (isCustomApiError(error)) {
      return error.response.message;
    }
    return error.message;
  }

  handleGoBack = () => {
    window.history.back();
  };

  public render() {
    if (this.state.error) {
      const message = ErrorBoundary.getErrorMessage(this.state.error);
      const { version } = this.props;
      const isClientOutdated =
        version &&
        version.versionNumber > String(process.env.VITE_CLIENT_VERSION);

      return (
        <Flex
          p={4}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box textAlign="left">
            <Heading mb={2} as="h1">
              Error
            </Heading>
            <SomethingWrong />

            <Box>
              {message && (
                <Badge mt={2} colorScheme="red">
                  {message}
                </Badge>
              )}
            </Box>

            {isClientOutdated ? (
              <Flex mt={4} alignItems="center" justifyContent="center" gap={2}>
                <Text fontSize="sm">
                  The version of the app you are using is outdated. Please click
                  "Update Now" to get the latest version.
                </Text>
                <Button
                  size="xs"
                  rightIcon={<AiOutlineReload />}
                  variant="success"
                  onClick={() => location.reload()}
                >
                  Update Now
                </Button>
              </Flex>
            ) : (
              <Flex mt={4} gap={2} justifyContent="center">
                <Button
                  onClick={() => {
                    if (this.state.retryAttempts === 3) {
                      location.reload();
                    }
                    if (this.props.queryClient) {
                      this.props.queryClient.clear();
                    }
                    this.setState({
                      error: undefined,
                      retryAttempts: this.state.retryAttempts + 1,
                    });
                  }}
                  variant="outline"
                >
                  Click here to retry
                </Button>
                <Button
                  onClick={this.handleGoBack}
                  variant="outline"
                  leftIcon={<AiOutlineArrowLeft />}
                >
                  Go Back
                </Button>
              </Flex>
            )}
          </Box>
        </Flex>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundaryHoc = withQueryClientAndBuild(ErrorBoundary);

export {
  ErrorBoundaryHoc as ErrorBoundary,
  ErrorBoundary as SimpleErrorBoundary,
};
