import { Card, TableVirtualised } from "components";

import { SortDirection } from "@intentsify/types";
import { TopPersonaWithIntent } from "@intentsify/types/dist/esm/BuyingGroup";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { useBuyingGroupInsight } from "../useBuyingGroupInsight";
import { useListTopPersonasWithIntent } from "./BuyingGroup.requests";
import { sortWithOtherLast } from "./components/utils";

export const useColumns = () => {
  return useMemo(() => {
    const columns: ColumnDef<TopPersonaWithIntent>[] = [
      {
        accessorKey: "personaName",
        header: "Persona Name",
        cell: (info) => info.getValue(),
        sortingFn: (rowA, rowB) =>
          sortWithOtherLast(
            rowA.original.personaName,
            rowB.original.personaName
          ),
      },
      {
        accessorKey: "jobLevel",
        header: "Job Level",
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "jobFunction",
        header: "Job Function",
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "bgInterest",
        header: "Persona Interest",
        cell: (info) => info.getValue(),
        sortingFn: (a, b) => {
          const order = { Low: 0, Medium: 1, High: 2 };
          return order[a.original?.bgInterest] - order[b.original?.bgInterest];
        },
      },
      {
        accessorKey: "topTopics",
        header: "Top Topics",
        cell: ({ row }) => row.original?.topTopics.join(", "),
      },
    ];

    return columns;
  }, []);
};

const TopPersonasWithIntentTable = (props: { campaignId: number }) => {
  const columns = useColumns();
  const buyingGroupInsight = useBuyingGroupInsight(props.campaignId);
  const { data: personasData = [] } = useListTopPersonasWithIntent(
    props.campaignId
  );

  return (
    <Card
      title="Top Personas With Intent"
      isLoading={buyingGroupInsight.isFetching}
      hasData={!buyingGroupInsight.isFetching && personasData.length > 0}
      isExpandable
    >
      <TableVirtualised<TopPersonaWithIntent>
        data={personasData}
        columns={columns}
        containerProps={{ width: "100%", height: "400px" }}
        defaultSortByColumn="personaName"
        defaultSortDirection={SortDirection.ASC}
      />
    </Card>
  );
};

export { TopPersonasWithIntentTable };
