import {
  CampaignDomainSource,
  DomainStatus,
  DSPProvider,
  SegmentType,
  TopLevelRegion,
} from "@intentsify/types";
import { apiService, Endpoints } from "api";
import { valueToQueryURL } from "utils";
import { z } from "zod";

export const uploadProgrammaticDomainsSchema = z.object({
  dspProvider: z.nativeEnum(DSPProvider),
  tag: z.string().min(3),
  region: z.nativeEnum(TopLevelRegion),
  hasIspData: z.boolean(),
  fileName: z.string(),
  domains: z.array(
    z
      .object({
        displayName: z.string(),
        domainId: z.number().optional(),
        domainStatus: z.nativeEnum(DomainStatus),
        source: z.nativeEnum(CampaignDomainSource),
      })
      .or(
        z.object({
          displayName: z.string(),
          domainId: z.never(),
          domainStatus: z.never(),
          source: z.nativeEnum(CampaignDomainSource),
          companyName: z.string().nullish(),
        })
      )
  ),
  segmentType: z.nativeEnum(SegmentType),
  targetPersonas: z.array(
    z.object({
      id: z.number(),
      name: z.string(),
    })
  ),
});

export type UploadProgrammaticDomains = z.infer<
  typeof uploadProgrammaticDomainsSchema
>;

export const uploadProgrammaticDomains = async ({
  tag,
  region,
  hasIspData,
  domains,
  fileName,
  segmentType,
  targetPersonas,
  dspProvider,
}: UploadProgrammaticDomains) => {
  await apiService.post(Endpoints.ProgrammaticDomains.Post.Upload, {
    domains,
    fileName,
    hasIspData,
    tag,
    region: valueToQueryURL(region),
    segmentType,
    targetPersonas,
    dspProvider,
  });
};
