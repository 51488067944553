import { Box, Flex, Text } from "@chakra-ui/layout";
import { CampaignMeta } from "@intentsify/types";
import { pluralize, toNumberDisplayValue } from "@intentsify/utils";
import { StatCardsContainer } from "components";
import { listFormat } from "shared/listFormat";
import { useBuyingGroupInsight } from "../useBuyingGroupInsight";
import { AccountsTable } from "./AccountsTable";
import { BuyingGroupContactByJobFunction } from "./BuyingGroupContactByJobFunction";
import { BuyingGroupContactsByPersona } from "./BuyingGroupContactByPersona";
import { BuyingGroupContactByJobLevel } from "./BuyingGroupContactsByJobLevel";
import { BuyingGroupInterestBreakdown } from "./BuyingGroupInterestBreakdown";
import { TopPersonasWithIntentTable } from "./TopPersonasWithIntentTable";

interface BuyingGroupInsightsProps {
  campaignId: number;
  weekNumber: number;
  yearNumber: number;
  campaignMeta?: CampaignMeta;
}

export function BuyingGroupInsights(props: BuyingGroupInsightsProps) {
  const buyingGroupInsight = useBuyingGroupInsight(props.campaignId);

  if (!buyingGroupInsight.data) {
    return (
      <Box>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          gap="1"
          mt="12"
          w="100%"
        >
          <Text fontSize="2xl">Buying group is not configured</Text>
          <Text color="blackAlpha.700" _dark={{ color: "whiteAlpha.700" }}>
            Reach out to your Sales rep to upgrade your package.
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Box width={"full"}>
      <Box px={4} mt={4}>
        <InfoCards campaignId={props.campaignId} />
      </Box>

      <Box px={4} mt={4} pb={24}>
        <AccountsTable
          campaignId={props.campaignId}
          weekNumber={props.weekNumber}
          yearNumber={props.yearNumber}
          campaignMeta={props.campaignMeta}
        />
      </Box>
    </Box>
  );
}

interface InfoCardsProps {
  campaignId: number;
}

function InfoCards(props: InfoCardsProps) {
  const buyingGroupInsight = useBuyingGroupInsight(props.campaignId);

  return (
    <Flex flexDir="column" gap={4}>
      <StatCardsContainer
        items={[
          {
            isLoading: buyingGroupInsight.isFetching,
            title: "Total active accounts",
            value: buyingGroupInsight.data?.active_domains_count,
            valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
            titleTooltip:
              "Accounts from your target account list showing intent activity and research relevant to the campaign.",
            noValueMessage: "No historical data available.",
          },
          {
            isLoading: buyingGroupInsight.isFetching,
            title: "Accounts with buying group intent",
            value: buyingGroupInsight.data?.active_accounts_with_intent,
            valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
            noValueMessage: "No historical data available.",
          },

          {
            isLoading: buyingGroupInsight.isFetching,
            title: "Buying Group Contacts for Active accounts",
            value: buyingGroupInsight.data?.total_contacts,
            valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
            noValueMessage: "No historical data available.",
          },
          {
            isLoading: buyingGroupInsight.isFetching,
            title: "Buying Group details",
            value: buyingGroupInsight.data?.details.total_personas,
            valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
            noValueMessage: "No historical data available.",
            description: pluralize(
              buyingGroupInsight.data?.details.total_personas ?? 0,
              "Target persona"
            ),
            caption: buyingGroupInsight.data?.details.matching_personas_list
              ? `Matching personas: ${listFormat.format(
                  // TODO: this will be fixed by ts5.5
                  (
                    buyingGroupInsight.data?.details
                      .matching_personas_list as unknown as string[]
                  ).filter(Boolean)
                )}`
              : "",
          },
        ]}
      />
      <Flex flexDir="row" gap={4} flexGrow={1}>
        <BuyingGroupInterestBreakdown campaignId={props.campaignId} />
        <TopPersonasWithIntentTable campaignId={props.campaignId} />
      </Flex>

      <Flex flexDir="row" gap={4}>
        <BuyingGroupContactByJobLevel campaignId={props.campaignId} />
        <BuyingGroupContactByJobFunction campaignId={props.campaignId} />
        <BuyingGroupContactsByPersona campaignId={props.campaignId} />
      </Flex>
    </Flex>
  );
}
