import { Nullable, WebAnalyticsAccountView } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { listAccountsTopUrls } from "../../ListView.requests";
import { Progress, Table } from "components";
import { Box } from "@chakra-ui/react";

type Props = {
  pixelId: number;
  accountView: WebAnalyticsAccountView;
  campaignId: Nullable<number>;
  domainId: number;
  startDate: string;
  endDate: string;
  visitedPages: string[];
  includeIspTraffic: boolean;
  includeKnownBotTraffic: boolean;
  includePotentialBotTraffic: boolean;
};

export const AccountsTopUrlsExpandableRow = ({
  pixelId,
  accountView,
  campaignId,
  startDate,
  endDate,
  domainId,
  visitedPages,
  includeIspTraffic,
  includeKnownBotTraffic,
  includePotentialBotTraffic,
}: Props) => {
  const { data: data, isFetching: isFetching } = useQuery({
    queryKey: [
      "webAnalyticsAccountsTopUrls",
      accountView,
      campaignId,
      startDate,
      endDate,
      domainId,
      visitedPages,
      includeIspTraffic,
      includeKnownBotTraffic,
      includePotentialBotTraffic,
    ],
    queryFn: () =>
      listAccountsTopUrls(pixelId, {
        accountView,
        campaignId,
        startDate,
        endDate,
        accountId: domainId,
        visitedPages,
        includeIspTraffic,
        includeKnownBotTraffic,
        includePotentialBotTraffic,
      }),
  });

  if (isFetching) {
    return <Progress isIndeterminate colorScheme="blue" />;
  }

  return (
    <Box maxHeight={400}>
      <Table
        data={data?.data || []}
        columns={[
          {
            header: "URL Visited",
            accessorKey: "url",
            cell: (info) => {
              return <Box fontSize="xs">{String(info.getValue())}</Box>;
            },
            enableSorting: false,
          },
          {
            header: "Page Views",
            accessorKey: "pageVisits",
            cell: (info) => {
              return <Box fontSize="xs">{String(info.getValue())}</Box>;
            },
            enableSorting: false,
          },
          {
            header: "Unique Visitors",
            accessorKey: "uniqueVisitors",
            cell: (info) => {
              return <Box fontSize="xs">{String(info.getValue())}</Box>;
            },
            enableSorting: false,
          },
        ]}
        hidePagination
      />
    </Box>
  );
};
