import { toNumberDisplayValue } from "@intentsify/utils";
import { StatCardsContainer } from "components";
import { useQuery } from "@tanstack/react-query";
import { getStatistics } from "../../Visualization.requests";
import { Props } from "../../../../WebAnalytics.types";

export const Statistics = ({
  pixelId,
  accountView,
  campaignId,
  startDate,
  endDate,
  accountsIds,
  visitedPages,
  includeIspTraffic,
  includeKnownBotTraffic,
  includePotentialBotTraffic,
}: Props) => {
  const { data: statistics, isFetching: isFetchingStatistics } = useQuery({
    queryKey: [
      "webAnalyticsStatistics",
      pixelId,
      accountView,
      campaignId,
      startDate,
      endDate,
      accountsIds,
      visitedPages,
      includeIspTraffic,
      includeKnownBotTraffic,
      includePotentialBotTraffic,
    ],
    queryFn: () =>
      getStatistics(pixelId, {
        accountView,
        campaignId,
        startDate,
        endDate,
        accountsIds,
        visitedPages,
        includeIspTraffic,
        includeKnownBotTraffic,
        includePotentialBotTraffic,
      }),
  });

  return (
    <StatCardsContainer
      items={[
        {
          isLoading: isFetchingStatistics,
          title: "Accounts visited",
          value: statistics?.cards.accountsVisited,
          valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
          titleTooltip:
            "Number of companies who have visited your website within the time frame selected.",
          noValueMessage: "No historical data available.",
          previousPeriodLabel: "vs Previous Week:",
          previousPeriodChange: statistics?.trends.accountsVisited || 0,
        },
        {
          isLoading: isFetchingStatistics,
          title: "Unique visitors",
          value: statistics?.cards.uniqueVisitors,
          valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
          titleTooltip:
            "Number of unique users who have visited your website within the time frame selected.",
          noValueMessage: "No historical data available.",
          previousPeriodLabel: "vs Previous Week:",
          previousPeriodChange: statistics?.trends.uniqueVisitors || 0,
        },
        {
          isLoading: isFetchingStatistics,
          title: "Total Page Views",
          value: statistics?.cards.totalPageViews,
          valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
          titleTooltip:
            "Amount of webpages visited within your website that is tagged with our Intentsify tag within the time frame selected.",
          noValueMessage: "No historical data available.",
          previousPeriodLabel: "vs Previous Week:",
          previousPeriodChange: statistics?.trends.totalPageViews || 0,
        },
      ]}
    />
  );
};
