import { Flex } from "@chakra-ui/react";
import { StatCard, StatCardProps } from "./StatCard";

type StatCardsContainerProps = {
  items: StatCardProps[];
  orientation?: "horizontal" | "vertical";
  mb?: number;
};

const StatCardsContainer = ({
  items,
  orientation = "horizontal",
  mb = 4,
}: StatCardsContainerProps) => {
  return (
    <Flex
      wrap="wrap"
      flexDirection={orientation === "horizontal" ? "row" : "column"}
      gap="4"
      mb={mb}
      w="100%"
    >
      {items.map((item, index) => {
        return (
          <Flex key={index} flex={1}>
            <StatCard {...item} />
          </Flex>
        );
      })}
    </Flex>
  );
};

export { StatCardsContainer };
